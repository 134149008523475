<template>
  <div>
    <Head title="Reports" />
    <h1 class="mb-8 text-3xl font-bold">Reporte</h1>

    <div class="bg-white p-5 flex">
      <text-input v-model="form.from" label="Desde" class="pb-8 pr-6 w-full lg:w-1/2" type="date" />
      <text-input v-model="form.to" label="Hasta" class="pb-8 pr-6 w-full lg:w-1/2" type="date" />
      <select-input v-model="form.type" label="Formato" class="pb-8 pr-6 w-full lg:w-1/2">
        <option value="">Seleccionar</option>
<!--        <option value="pdf">PDF</option>-->
        <option value="excel">Excel</option>
      </select-input>
      <div class="mt-6">
        <button class="btn-blue" @click="generateReport()">Generar reporte</button>
      </div>
    </div>
    <div v-if="count" class="pt-3">
      <a :href="`/orders-${form.orderNumber}.xlsx`">Descarga aqui</a>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from "@/Shared/SelectInput.vue";

export default {
  components: {
    SelectInput,
    TextInput,
    Head,
  },
  layout: Layout,
  props: {
    order: String,
  },
  data() {
    return {
      form: this.$inertia.form({
        from: '',
        to: '',
        type: 'excel',
        full: false,
        orderNumber: Math.floor(Math.random() * (59999 - 10) + 10)
      }),
      count: 0
    }
  },
  methods: {
    getRandom() {
        this.form.orderNumber = Math.random() * (59999 - 10) + 10
    },
    generateReport(){
      return this.form.post('/orders/report', {onSuccess: (data) => {
          this.count = 1
          console.log(data)
        }})


    },
  },
}
</script>
