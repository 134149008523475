<template>
  <div>
    <div class="mb-4">
      <Link class="group flex items-center py-3" href="/">
        <icon name="dashboard" class="mr-2 w-4 h-4" :class="isUrl('') ? 'fill-white' : 'fill-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Escritorio</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin'">
      <Link class="group flex items-center py-3" href="/groups">
        <i :class="isUrl('groups') ? 'fa fa-users mr-2 text-white' : 'fa fa-users mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('groups') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Grupo</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin'">
      <Link class="group flex items-center py-3" href="/deposits">
        <i :class="isUrl('deposits') ? 'fa fa-building-o mr-2 text-white' : 'fa fa-building mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('deposits') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Depositos</div>
      </Link>
    </div>
    <!--<div class="mb-4" v-if="role == 'admin'">-->
    <!--  <Link class="group flex items-center py-3" href="/requests">-->
    <!--    <i :class="isUrl('requests') ? 'fa fa-file mr-2 text-white' : 'fa fa-file mr-2 text-blue-400 group-hover:fill-white'" />-->
    <!--    <div :class="isUrl('requests') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Solicitudes</div>-->
    <!--  </Link>-->
    <!--</div>-->
    <div v-if="role !== 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/products">
        <i :class="isUrl('products') ? 'fa fa-shopping-cart mr-2 text-white' : 'fa fa-shopping-cart mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('products') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Productos</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin' || role == 'sales'">
      <Link class="group flex items-center py-3" href="/clients">
        <i :class="isUrl('clients') ? 'fa fa-address-book mr-2 text-white' : 'fa fa-address-book mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('clients') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Clientes</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin' || role == 'endline' || role == 'storer'">
      <Link class="group flex items-center py-3" href="/inventories">
        <i :class="isUrl('inventories') ? 'fa fa-th-large mr-2 text-white' : 'fa fa-th-large mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('inventories') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Inventario</div>
      </Link>
    </div>
    <!--<div class="mb-4" v-if="role == 'storer' || role == 'admin' || role == 'endline'">
      <Link class="group flex items-center py-3" href="/returns">
        <i :class="isUrl('returns') ? 'fa fa-th-large mr-2 text-white' : 'fa fa-th-large mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('returns') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Devolucion</div>
      </Link>
    </div> -->
    <div v-if="role == 'sales' || role == 'admin' || role == 'storer' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/orders">
        <i :class="isUrl('orders') ? 'fa fa-shopping-cart mr-2 text-white' : 'fa fa-shopping-cart mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('orders') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Ordenes</div>
      </Link>
    </div>
    <div v-if="role == 'sales' || role == 'admin'" class="mb-4">
      <Link class="group flex items-center py-3" href="/reports">
        <i :class="isUrl('reports') ? 'fa fa-file-o mr-2 text-white' : 'fa fa-file mr-2 text-blue-400 group-hover:fill-white'" />
        <div :class="isUrl('reports') ? 'text-white' : 'text-blue-300 group-hover:text-white'">Reportes</div>
      </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
    Link,
  },
  data()
  {
    return {
      role: this.$parent.auth.user.role
    }
  },
  methods: {
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    },
  },
}
</script>
