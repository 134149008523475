<template>
  <div>
    <Head title="Productos" />
    <h1 class="mb-8 text-3xl font-bold">Productos</h1>
    <div class="flex items-center justify-between mb-6">
      <Link class="btn-blue mr-2" href="/products/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Producto</span>
      </Link>

      <div>

        <button class="btn-blue mr-2" @click="searchForm = !searchForm">
          <i class="fa fa-search"></i>
        </button>

        <button class="btn-blue" @click="imported = !imported">
          Importar
        </button>
        <form v-if="imported" action="/products/import" method="post" enctype="multipart/form-data">
          <input name="_token" type="hidden" :value="csrf">
          <input type="file" name="file" id="">
          <button type="submit" class="btn-blue">
            <i class="fa fa-upload"></i>
          </button>
        </form>
      </div>
    </div>

    <div v-if="searchForm" class="flex border-blue-100 p-2">
      <text-input label="Por Términos" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.search.terms" />
      <select-input v-model="form.search.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region">
        <option value="">Seleccionar</option>
        <option v-for="(region, key) in regions" :key="key" :value="region.id">{{ region.name }}</option>
      </select-input>
      <select-input v-model="form.search.deposit" class="pb-8 pr-6 w-full lg:w-1/2" label="Deposito">
        <option value="">Seleccionar</option>
        <option v-for="(deposit, key) in deposits" :key="key" :value="deposit.id">{{ deposit.name }}</option>
      </select-input>
      <select-input v-model="form.search.truck_model_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Modelo">
        <option value="">Seleccionar</option>
        <option v-for="(model, key) in models" :key="key" :value="model.id">{{ model.name }}</option>
      </select-input>
      <select-input v-model="form.search.part_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Parte">
        <option value="">Seleccionar</option>
        <option v-for="(part, key) in parts" :key="key" :value="part.id">{{ part.name }}</option>
      </select-input>
    </div>


    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <th class="pb-4 pt-6 px-6" style="width: 100px;">Depositos</th>
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Modelos</th>
          <th class="pb-4 pt-6 px-6">Cantidad</th>
          <th class="pb-4 pt-6 px-6">Marca</th>
        </tr>
        <tr v-for="product in products.data" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.code }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDeposits(product.inventory) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.name }}
            </Link>
          </td>
          <td class="border-t">
            <span v-for="model in product.models" :key="model.id">
              {{ model.truck_model.name }},
            </span>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getQuantity(product.inventory) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.brand.name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="products.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import Pagination from "@/Shared/Pagination.vue";
import SearchFilter from "@/Shared/SearchFilter.vue";
import throttle from "lodash/throttle";
import pickBy from "lodash/pickBy";
import TextInput from "@/Shared/TextInput.vue";
import SelectInput from "@/Shared/SelectInput.vue";

export default {
  components: {
    SelectInput, TextInput,
    SearchFilter,
    Pagination,
    Head,
    Icon,
    Link,
  },
  layout: Layout,
  props: {
    products: Object,
    groups: Object,
    csrf: String,
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object
  },
  data() {
    return {
      // eslint-disable-next-line no-unused-vars
      getDeposits(inventory)
      {
        let deposits = '';
        for (let inventory of inventory) {
          deposits += `${inventory.deposit.name}, `
        }

        return deposits;
      },
      // eslint-disable-next-line no-unused-vars
      getQuantity(inventory)
      {
        let quantity = 0;
        for (let inventory of inventory) {
          quantity += inventory.quantity
        }

        return quantity;
      },
      searchForm: false,
      form: this.$inertia.form({
        search: {
          terms: "",
          deposit: "",
          brand_id: "",
          part_id: "",
          truck_model_id: "",
          region_id: ""
        }
      }),
      imported: false
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/products', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {

    }
  }
}
</script>
