<template>
  <div>
    <Head title="Productos" />
    <h1 class="mb-8 text-3xl font-bold">Inventarios</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <div class="p-5">
        <text-input v-model="form.code" label="Codigo de producto" placeholder="Ingresa un codigo de producto" />
        <text-input v-model="prd.name" label="Nombre de producto" disabled placeholder="Producto" />
        <loading-button class="btn-indigo mx-4 my-4" type="button" @click="findProduct">Buscar</loading-button>
        <loading-button v-if="product" class="btn-indigo mx-4 my-4" type="button" @click="products.push(product)">Agregar</loading-button>
      </div>
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-3">Cantidad</th>
          <th class="pb-4 pt-6 px-3">Deposito</th>
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Cantidad</th>
          <th class="pb-4 pt-6 px-6">Marca</th>
        </tr>
        <tr v-for="product in products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="boder-t px-4 py-4">
            <text-input v-model="quantities.qtys[product.id]" type="number" placeholder="Cantidad" />
          </td>
          <td class="boder-t px-4 py-4">
            <select-input v-model="quantities.deposits[product.id]">
              <option>Seleccionar</option>
              <option v-for="deposit in deposits" :key="deposit.id">{{deposit.name}}</option>
            </select-input>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.code }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.quantity }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product?.brand.name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
      <loading-button class="btn-indigo mx-4 my-4" type="button" @click="generate">Crear entrada de productos</loading-button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import pickBy from "lodash/pickBy";
import SelectInput from "@/Shared/SelectInput.vue";
      
export default {
  components: {
    Head,
    Link,
    TextInput,
    SelectInput
  },
  layout: Layout,
  props: {
    product: Object,
    deposits: Array,
  },
  data(){
    return {
      quantities: this.$inertia.form({
        qtys: [],
        products: [],
        deposits: [],
      }),
      form: this.$inertia.form({
          code: ''
      }),
      products: [],
      prd: {
        name: ""
      }
    }
  },
  methods: {
    generate(){
      this.quantities.post('/inventories')
    },
    findProduct() {
      this.$inertia.get('/inventories/create', pickBy({code: this.form.code}), {
        preserveState: true,
        preserveScroll: true,
        replace: true
      })
      this.prd.name = this.product.name
    }
  },

}
</script>
        